import initSentry from '@readme/server-shared/sentry-browser'; // eslint-disable-line readme-internal/no-restricted-imports
import { useCallback, useContext, useMemo } from 'react';

import { ConfigContext, UserContext, ProjectContext } from '@core/context';

const useSentry = component => {
  const config = useContext(ConfigContext);
  const user = useContext(UserContext);
  const { project } = useContext(ProjectContext);

  const Sentry = useMemo(() => {
    const S = initSentry(component, config);

    if (user) {
      S.setUser({
        id: user?._id || user?.id || '',
        name: user?.name || '',
      });
    }

    if (project) {
      S.setContext('project', {
        id: project._id || '',
        name: project.name || '',
        subdomain: project.subdomain || '',
      });
    }

    return S;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useCallback(
    (err, errData) => {
      // eslint-disable-next-line no-console
      console.error(err);

      Sentry.withScope(scope => {
        if (errData && errData.supportErrorCode) {
          scope.setTag('support-code', errData.supportErrorCode);
        }

        Sentry.captureException(err);
      });
    },
    [Sentry],
  );
};

export default useSentry;
