const Sentry = require('@sentry/browser');
const { Integrations: ApmIntegrations } = require('@sentry/tracing');

/**
 * This file is exclusively for reporting errors from a browser to Sentry.
 *
 * Why not use `lib/sentry.js`? As @sentry/node requires the `fs` Node module, loading @sentry/browser within the same
 * file causes `fs is undefined` errors in the browser even when doing conditional loading as Webpack packages up the
 * whole thing.
 */
module.exports = (component = 'dash', config) => {
  if (!config.sentry || !config.sentry.enabled || !config.sentry.dsn || !component) return Sentry;

  const environment = (process && process.env && process.env.NODE_ENV) || 'development';

  Sentry.init({
    dsn: config.sentry.dsn,
    environment,
    release: config.releaseVersion || '',
    tracesSampleRate: config.sentry.tracesSampleRate,
    integrations: [new ApmIntegrations.BrowserTracing()],
    // Filter bug traffic to Sentry -> Only allow errors originating from our own bundles
    // Ideally removes errors from user included script tags
    // Note: we'll be inclusive of any readme domains, not just cdn.{domain} URLs
    allowUrls: [new URL(config.asset_url).origin, /https:\/\/[^/]*readme.(?:com|io)(?:\/|$)/],
    initialScope: { tags: { 'readme-component': component } },
  });

  return Sentry;
};
